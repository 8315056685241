body {
  font-family: _font(family-default);
  background-color: rgb(17,0,51);
  color: _color(body-text);
  font-size: 16px;
  font-weight: unset;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: _breakpoint(step-min);
  background-image: url(../../images/bg.gif);
}
