.margin-top-1 {
  margin-top: 1rem;
}

.margin-right-sm {
  margin-right: .5rem;
}

.margin-left-sm {
  margin-left: .5rem;
}

.margin-left-med {
  margin-left: 1rem;
}

.margin-y-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

margin-auto {
  margin: 0 auto;
}
