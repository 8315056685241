ul {
  margin: 0 0 0 2rem;
  padding: 0;


  li {
    font-size: .875rem;
    line-height: 1.5rem;
  }

}
