h1, h2, h3, h4 {
  color: _color(heading);
  margin: 0;
  padding: 0;
  font-weight: unset;
}

h1 {
  font-family: _font(font-default);
  font-size: 2rem;
  line-height: 2.5rem;
  padding-top: 1.75rem;
  padding-bottom: 2.5rem;
  font-weight: 600;
}

h2 {
  font-family: _font(font-default);
  font-size: 1.5rem;
  font-weight: 600;
}

h3 {
  font-family: _font(font-default);
  font-size: 1rem;
  line-height: 1.75rem;
  padding-bottom: .5rem;
  font-weight: 500;
}

h4 {
  font-family: _font(font-default);
  font-size: .75rem;
  padding-top: 1rem;
  padding-bottom: .5rem;
}


table, p, em, div, input {
  font-size: _font(size-regular);
  margin: 0;
  padding: 0;
}

p + p {
  margin-top: .5rem;
}



.bold {
  font-family: _font(font-default);
}
.semibold {
  font-family: _font(font-default);
}
.regular {
  font-family: _font(font-default);
}
.medium {
  font-family: _font(font-default);
}
.light {
  font-family: _font(font-default);
}

.text-highlight {
  color: _color(tertiary);
}

.text-white {
  color: _color(white);
}



