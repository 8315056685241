.width-2 {
  width: 2rem;
}

.width-5 {
  width: 5rem;
}

.width-100 {
  width: 100%;
}

.max-width-8 {
  max-width: 8rem;
}

.min-width-8 {
  min-width: 8rem;
}

.max-width-10 {
  max-width: 10rem;
}

.min-width-10 {
  min-width: 10rem;
}

.max-width-20 {
  max-width: 20rem;
}

.min-width-20 {
  min-width: 20rem;
}

