.padding-0 {
  padding: 0;
}

.padding-1 {
  padding: 1rem;
}

.padding-1-5 {
  padding: 1.5rem;
}


.padding-2 {
  padding: 2rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-y-sm {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.padding-y-med {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.padding-y-lg, .padding-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding-y-xl {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padding-left-sm {
  padding-left: .5rem;
}

.padding-right-sm {
  padding-right: .5rem;
}

.padding-left-1 {
  padding-left: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}
