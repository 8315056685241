nav {
  box-sizing: border-box;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    list-style: none;
    margin-right: 1.5rem;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      color: _color(primary);
      font-weight: 600;

      &:hover {
        color: _color(link);
      }
    }
  }

  .material-icons {
    color: _color(nav-icons-primary);
  }

}


@media only screen and (min-width: _breakpoint(step-one)) {



}
