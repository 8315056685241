.material-icons {
  font-family: 'material-icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  //display: inline-block;
  padding-right: .5rem;
}

.highlight {
  .material-icons {
    font-size: 1.2rem;
  }
}
